<template>
  <b-modal
    id="redirectErrorModal"
    no-close-on-esc
    no-close-on-backdrop
    hide-header
    centered
  >
    <template v-slot:default>
      <div v-html="message"></div>
    </template>
    <template v-slot:modal-footer>
      <b-button
        class="mt-2 mx-auto w-100 submit-policy"
        variant="submit"
        block
        @click="accept()"
      >
        ตกลง
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      message: `<div style="text-align: center;"><h3><b>ขออภัยค่ะ</b></h3><h5><b>สัญญาของท่านไม่ตรงตามเงื่อนไข<br />การเข้าใช้บริการที่บริษัทฯ กำหนด</b></h5><br /><b>สอบถามเพิ่มเติม โทร.&nbsp;<a href="tel:1317" style="color: #80c141;"><u>1317</u></a></b></div>`,
      des: ""
    };
  },
  methods: {
    accept() {
      this.$emit("Accept", this.des);
      this.$bvModal.hide("redirectErrorModal");
    }
  }
};
</script>

<style></style>
